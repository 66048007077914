import React, { useState, useEffect, useRef } from "react";
import css from "../css/gamehis.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Invoice from "./Invoice";
import { AiOutlineDownload } from "react-icons/ai";
import "./invoice.css";
import socket from "../Components/socket";
import { BsFillEyeFill } from "react-icons/bs";
import Refer from "./Refer";
import Gamehistory from "./Gamehistory";
import Referralhis from "./Referralhis";
import "../../app2.js/css/landing.css";
import Transactionhistory from "./Transactionhistory";
import allHistory from "./Gamehistory";

const Newhistory = () => {
  const [show, setShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState();

   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
 // +++++++++++++++++++++++++++++ 




  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setInvoiceData(e);
  };

  const [cardData, setCardData] = useState()


  const role = async () => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axios
      .get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data);
        if (res.data) {
          allHistory(res?.data?.id);
        }
      });
  };

  const [allHistoryData, setAllHistoryData] = useState();
  // console.log(allHistoryData);

  const allHistory = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setIsLoading(true);
    {
    
    }
    await axios
      .get(baseUrl+`txn_history/user/${id}`, {
        headers,
      })
      .then((res) => {
        // console.log(res.data)
        setCardData(res.data);
        
      });
  };

  useEffect(() => {
    role();
  }, []);

  const dateFormat = e => {
    const date = new Date(e)
    const newDate = date.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      hour12: true,
      minute: 'numeric'
    })
    return newDate
  }

   // change theme 
   const theme  = localStorage.getItem('theme')
  
   const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
  };

  return (
    <>
      <div className="leftContainer mb_space" style={{...styles, height:"auto" }}>
        <div className="  Orher_page_main_section" style={{...styles, height:"auto"}}>
          <section className="transaction_main_section_modal">
            <div className="">
              <h2 className="profile_headings mt-5"> History</h2>

              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    // onClick={notification_list}
                    className="nav-link active tabs"
                    id="informative-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#informative"
                    type="button"
                    role="tab"
                    aria-controls="informative"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="game-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#game"
                    type="button"
                    role="tab"
                    aria-controls="game"
                    aria-selected="true"
                  >
                    game
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="transaction-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#transaction"
                    type="button"
                    role="tab"
                    aria-controls="transaction"
                    aria-selected="false"
                  >
                    transaction
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="others-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#others"
                    type="button"
                    role="tab"
                    aria-controls="others"
                    aria-selected="false"
                    onClick={Refer}
                  >
                    Referral
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="informative"
                  role="tabpanel"
                  aria-labelledby="informative-tab"
                >
                  {/* {console.log(cardData)} */}
                  {cardData &&
                    cardData.map((card) => {
                      var id = card._id.toString(),
                        ctr = 0;
                      var timestamp = id.slice(ctr, (ctr += 8));
                      var machineID = id.slice(ctr, (ctr += 6));
                      var processID = id.slice(ctr, (ctr += 4));
                      var counter = id.slice(ctr, (ctr += 6));
                     
                      var counterid = parseInt(timestamp, 16);

                      if (
                        (card.Req_type === "deposit" ||
                          card.Req_type === "bonus") &&
                        card.status != "FAILED"
                      ) {
                        var titleMsg = "Cash added";
                        if (
                          card.status === "Pending" &&
                          card.Req_type === "deposit"
                        ) {
                          var signIcon = <div className="text-danger">(X)</div>;
                        } else {
                          var signIcon = (
                            <div className="text-success">(+)</div>
                          );
                        }
                      } else if (
                        card.Req_type === "withdraw" &&
                        card.status != "FAILED"
                      ) {
                        var titleMsg = "Witdraw using " + card.Withdraw_type;
                        var signIcon = <div className="text-danger">(-)</div>;
                      } else if (
                        card.Req_type === "penalty" &&
                        card.status != "FAILED"
                      ) {
                        var titleMsg = "Penalty";
                        var signIcon = <div className="text-danger">(-)</div>;
                      } else if (
                        card.status === "Pending" ||
                        card.status === "FAILED"
                      ) {
                        var titleMsg = "FAILED";
                        var signIcon = <div className="text-danger">(X)</div>;
                      } else {
                        var titleMsg = "";
                        var signIcon = <div className="text-success"></div>;
                      }

                      return (
                        <>
                           <div className='other_pages_card_main'>
                          <div>
                            <h3>{titleMsg}</h3>
                            <h3>
                              Order ID:{' '}
                              <span className='dark_light_color'>
                                {' '}
                                {counterid}
                              </span>
                            </h3>
                            <p>
                              Status:
                              <span className='dark_light_color'>
                                {card.status === 'none' ||
                                (card.status === 'Pending' &&
                                  card.Req_type === 'withdraw')
                                  ? 'Processing'
                                  : card.status === 'Pending' &&
                                    card.Req_type === 'deposit'
                                  ? 'Cancelled'
                                  : card.status}
                              </span>
                            </p>
                            <p className='dander_text transaction_msg'>
                              {' '}
                              {card.txn_msg ? card.txn_msg : ''}
                            </p>
                          </div>
                         
                          <div className='transaction_right_side_main'>
                            {/* <button
                              onClick={handleShowBottom}
                              className='eye_button'
                            >
                              <BsFillEyeFill className='icons' />
                            </button> */}
                            <div className='card_rupee d-flex float-right'>
                              {signIcon}
                              <picture className=''>
                                <img
                                  alt='img'
                                  height='21px'
                                  width='21px'
                                  src={
                                    process.env.PUBLIC_URL +
                                    '/nkcoin.png'
                                  }
                                  className='snip-img'
                                />
                              </picture>
                              <span className='pl-1'>{card.amount}</span>
                            </div>
                            <div>
                              {card.closing_balance && (
                                <div
                                  className={`${css.games_section_headline}`}
                                  style={{
                                    fontSize: '0.8em',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  closing balance :{card.closing_balance}
                                </div>
                              )}
                            </div>
                            <div className='card_date'>
                              <p>{dateFormat(card.createdAt).split(',')[0]}</p>
                              <p>{dateFormat(card.createdAt).split(',')[1]}</p>
                            </div>
                          </div>
                        </div> 

                     
                        </>
                      );
                    })}
                </div>
                <div
                  className="tab-pane fade "
                  id="game"
                  role="tabpanel"
                  aria-labelledby="game-tab"
                >
                  <Gamehistory />
                </div>
                <div
                  className="tab-pane fade"
                  id="transaction"
                  role="tabpanel"
                  aria-labelledby="transaction-tab"
                >
                  <Transactionhistory />
                </div>
                <div
                  className="tab-pane fade"
                  id="others"
                  role="tabpanel"
                  aria-labelledby="others-tab"
                >
                  <Referralhis />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Newhistory;
