import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App2 from "./app2.js/App2";
import "./app/App.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "remixicon/fonts/remixicon.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-crop/src/ReactCrop.scss";
import { LanguageProvider } from "./app2.js/Components/LanguageContext";
import { ThemeProvider } from "./ThemeContext";

ReactDOM.render(
  <BrowserRouter>
  <ThemeProvider>
    <LanguageProvider>
    <App2 />
    </LanguageProvider>
  </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
