import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import socket from '../Components/socket'
import '../css/landing.css'
import Rightcontainer from '../Components/Rightcontainer'
import Downloadbutton from '../Components/Downloadbutton'
import io from '../Components/socket'
import Header from '../Components/Header'
import { Token } from '@mui/icons-material'
import SliderCode from '../Components/SliderCode'
import { LanguageContext } from '../Components/LanguageContext'
import { useTheme } from '../../ThemeContext'

export default function Landing ({ userAllData }) {
  const [open, setOpen] = useState(false)
  // const [userAllData, setUserAllData] = useState()

  // language
  const translations = {
    hi: { Tournaments: 'प्रतियोगिता' },
    en: { Tournaments: 'Tournaments' },
  }; 
  const { language } = useContext(LanguageContext);

  const addsetting = localStorage.getItem('sitSetting')
  const addCaseSetting = JSON.parse(addsetting)
  // console.log(addCaseSetting);

  // const role = async () => {
  //   const access_token = localStorage.getItem('token')
  //   io.emit('getprofile', access_token)
  //   io.on('setProfile', data => {
  //     // setUserAllData(data)
  //   })
  // }

  useEffect(() => {
    // role()
  }, [])

  // change theme 
  const theme = localStorage.getItem("theme")

  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
    // padding: '20px',
    // margin: '20px',
  };
  
  const styles3 = {
    backgroundColor: theme === 'light' ? '#facc06' : '#27241b',
    color: theme === 'light' ? 'black' : '#fff',
  };

  return (
    <>
      <div className='leftContainer mb_space' style={{...styles, height:"119% !important"}}>
        <div className='pt-5 mt-5   Orher_page_main_section' style={styles}>
    <SliderCode/>   
          <div className='main-area'style={styles}>
            <div className='gameCard pt-2' style={styles}>
              <div className='alrt_msg_div'style={styles3}>
                <h5 className=''>
                  {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                    ? ''
                    : JSON.parse(localStorage.getItem('sitSetting'))
                        ?.site_message}
                </h5>
              </div>
            </div>
            {/* {userAllData?.verified === 'unverified' ||
            userAllData?.verified === 'reject' ? (
              <div className='gameCard pt-0'>
                <div
                  className='alrt_msg_div'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}
                >
                  <span
                    className='float-left'
                    style={{ marginRight: '45px', width: 'auto' }}
                  >
                    KYC Pending !
                  </span>
                  <Link
                    to='/profile'
                    className='btn float-right p-3'
                    style={{
                      backgroundColor: 'black',
                      color: 'white !important',
                      width: 'auto'
                    }}
                  >
                    {' '}
                    Complete Here{' '}
                  </Link>
                </div>
              </div>
            ) : (
              ' '
            )} */}

            <section className='games-section'style={styles}>
              {/* <div className='d-flex align-items-center justify-content-between mt-3 mb-0'>
                <h3 className=' profile_headings' style={styles}>{translations[language].Tournaments}</h3>
                YOUTUBE LINK 
                <a
                  href='https://youtu.be/0hNHiccgoMI?si=4lT6dx1N4Ee2K02h'
                  target='_blank'
                >
                  <span
                    className='d-flex align-items-center  btn-md guide_btn'
                    style={{
                      right: '0.5rem',
                      backgroundColor: '#003441',
                      color: 'white',
                      borderRadius: '4px',
                      padding: '4px 13px',
                      fontSize: '12px'
                    }}
                  >
                    Youtube
                    <img
                      className='ml-2'
                      src={'/images/Homepage/youtube.png'}
                      alt=''
                      width='15px'
                    />
                  </span>
                </a>
              </div> */}
              <div className='games-section-headline mt-0 mb-1'>
                <div className='games-window '>
                  {/* MANUAL CLASSIC   */}
                  {/* {localStorage.getItem("token") ? (
                    <Link
                      className="gameCard-container"
                      style={{
                        pointerEvents: !addCaseSetting?.isLudokingPopular
                          ? "none"
                          : "",
                      }}
                      to={`/Homepage/ludoClassicManual`}
                    >
                      <Link
                        className={`gameCard`}
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingPopular
                            ? "none"
                            : "",
                        }}
                        to={`/Homepage/ludoClassicManual`}
                      >
                        {addCaseSetting?.isLudokingPopular && (
                          <span className="d-none blink text-light d-block text-right bg_green game_live_status_position">
                            ◉ LIVE
                          </span>
                        )}
                        <picture className="gameCard-image">
                          <img
                            width="100%"
                            src="./mainimage/ludoclassicmanual.jpg"
                            alt=""
                          />
                        </picture>
                      </Link>
                    </Link>
                  ) : (
                    ""
                  )} */}
                  {/* CLASSIC CLASSIC   */}

                  {/* <Link
                    className="gameCard-container"
                    style={{
                      pointerEvents: !addCaseSetting?.isLudokingClassic
                        ? "none"
                        : "",
                    }}
                    to={`/Homepage/Ludo%20Classics`}
                  >
                    <Link
                      className={`gameCard`}
                      style={{
                        pointerEvents: !addCaseSetting?.isLudokingClassic
                          ? "none"
                          : "",
                      }}
                      to={`/Homepage/Ludo%20Classics`}
                    >
                      {addCaseSetting?.isLudokingClassic && (
                        <span className="d-none blink text-light d-block text-right bg_green game_live_status_position">
                          ◉ LIVE
                        </span>
                      )}
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="./mainimage/ludoclassicauto.jpg"
                          alt=""
                        />
                      </picture>
                    </Link>
                  </Link> */}

                  <>
                   
                    {/* LUDO CLASSIC MANUAL */}
                    <Link
                      className='gameCard-container mt-3'
                      style={{
                        pointerEvents: !addCaseSetting?.isLudokingPopular
                          ? 'none'
                          : ''
                      }}
                      to={`/Homepage/ludoClassicManual`}
                    >
                      <Link
                        className={`gameCard`}
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingPopular
                            ? 'none'
                            : ''
                        }}
                        to={`/Homepage/ludoClassicManual`}
                      >
                        {addCaseSetting?.isLudokingPopular && (
                          <span className='d-none blink text-light d-block text-right bg_green game_live_status_position'>
                            ◉ LIVE
                          </span>
                        )}
                        <picture className='gameCard-image'>
                          <img
                            width='100%'
                            src='./mainimage/ludoclassic.jpg'
                            alt=''
                          />
                        </picture>
                      </Link>
                    </Link>

                    {/* LUDO Rich */}
                    <Link
                        className='gameCard-container mt-3'
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingPopular
                            ? 'none'
                            : ''
                        }}
                        to={`#`}
                      >
                        <Link
                          className='gameCard'
                          style={{
                            pointerEvents: !addCaseSetting?.isLudokingPopular
                              ? 'none'
                              : ''
                          }}
                          // to={`/Homepage/ludoClassicRich`}
                          to={`#`}
                        >
                          {/* {addCaseSetting?.isLudokingPopular && ( */}
                            <span className='d-none blink text-light d-block text-right bg_green game_live_status_position'>
                              ◉ Comming Soon
                            </span>
                          {/* )} */}

                          <picture className='gameCard-image'>
                            <img
                              width='100%'
                              src='./mainimage/ludoulta.jpg'
                              alt=''
                            />
                          </picture>
                        </Link>
                        <div className='goverlay'>
                          <div className='text'>Comming Soon</div>
                        </div>
                      </Link>



                    {/* Avaitor */}
                    <Link
                        className='gameCard-container mt-3'
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingPopular
                            ? 'none'
                            : ''
                        }}
                        to={`#`}
                      >
                        <Link
                          className='gameCard'
                          style={{
                            pointerEvents: !addCaseSetting?.isLudokingPopular
                              ? 'none'
                              : ''
                          }}
                          to={`#`}
                        >
                          {/* {addCaseSetting?.isLudokingPopular && ( */}
                            <span className='d-none blink text-light d-block text-right bg_green game_live_status_position'>
                              ◉ Coming Soon
                            </span>
                          {/* )} */}

                          <picture className='gameCard-image'>
                            <img
                              width='100%'
                              src='./mainimage/aviator.png'
                              alt=''
                            />
                          </picture>
                        </Link>
                        <div className='goverlay'>
                          <div className='text'>Comming Soon</div>
                        </div>
                      </Link>

                    {/* Support */}
                    <Link
                        className='gameCard-container mt-3'
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingPopular
                            ? 'none'
                            : ''
                        }}
                        to={`#`}
                      >
                        <Link
                          className='gameCard'
                          style={{
                            pointerEvents: !addCaseSetting?.isLudokingPopular
                              ? 'none'
                              : ''
                          }}
                          to={`#`}
                        >
                          {/* {addCaseSetting?.isLudokingPopular && ( */}
                            <span className='d-none blink text-light d-block text-right bg_green game_live_status_position'>
                              ◉ Comming Soon
                            </span>
                          {/* )} */}

                          <picture className='gameCard-image'>
                            <img
                              width='100%'
                              src='./mainimage/nksupport.png'
                              alt=''
                            />
                          </picture>
                        </Link>
                        <div className='goverlay'>
                          <div className='text'>Comming Soon</div>
                        </div>
                      </Link>
                   

                    {/* LUDO SNAKE  */}
                    {/* <Link
                      className='gameCard-container mt-3'
                      style={{
                        pointerEvents: !addCaseSetting?.isLudokingSnake
                          ? 'none'
                          : ''
                      }}
                      to={'/Homepage/LudoSnake'}
                    >
                      <Link
                        className='gameCard  '
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingSnake
                            ? 'none'
                            : ''
                        }}
                        to={'/Homepage/LudoSnake'}
                      >
                        {addCaseSetting?.isLudokingSnake && (
                          <span className='d-none blink text-light d-block text-right bg_green game_live_status_position pt-2'>
                            ◉ LIVE
                          </span>
                        )}

                        <picture className='gameCard-image'>
                          <img
                            width='100%'
                            src='./mainimage/ludosnakemanual.jpg'
                            alt=''
                          />
                        </picture>
                      </Link>
                      <div className='goverlay'>
                        <div className='text'>Comming Soon</div>
                      </div>
                    </Link> */}
                    {/* rummy */}
                    {/* <Link
                      className='gameCard-container mt-3'
                      style={{
                        pointerEvents: !addCaseSetting?.isLudokingClassic
                          ? 'none'
                          : ''
                      }}
                      to={`/Homepage/ludoClassicManual/new`}
                    >
                      <Link
                        className='gameCard '
                        style={{
                          pointerEvents: !addCaseSetting?.isLudokingClassic
                            ? 'none'
                            : ''
                        }}
                        to={`/Homepage/ludoClassicManual/new`}
                      >
                        {addCaseSetting?.isLudokingClassic && (
                          <span className='d-none blink text-light d-block text-right bg_green game_live_status_position'>
                            ◉ LIVE
                          </span>
                        )}

                        <picture className='gameCard-image'>
                          <img
                            width='100%'
                            src='./mainimage/rummy.jpg'
                            alt=''
                          />
                        </picture>
                      </Link>
                      <div className='goverlay'>
                        <div className='text'>◉ LIVE </div>
                      </div>
                    </Link> */}
                    {/* carromkingauto */}
                  
                  </>
                  

                  {/* <Link className='gameCard-container'>
                    <Link
                      className='gameCard pt-2 '
                      style={{
                        pointerEvents: !addCaseSetting?.isNKADDAClassic
                          ? 'none'
                          : ''
                      }}
                      to={'/Homepage/NKADDAClassic'}
                    >
                      {addCaseSetting?.isNKADDAClassic && (
                        <span className='d-none blink text-danger d-block text-right'>
                          ◉ Comming Soon
                        </span>
                      )}

                      <picture className='gameCard-image'>
                        <img
                          width='100%'
                          src='./mainimage/NKADDAclassic.webp'
                          alt=''
                        />
                      </picture>
                    </Link>
                    <div className='goverlay'>
                      <div className='text'>Comming Soon</div>
                    </div>
                  </Link> */}
                </div>
                <div>
                  
                </div>
              </div>
            </section>

            <div className='downloadButton'>
              <Downloadbutton />
            </div>
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </>
  )
}
