// import { width } from '@mui/system';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import BottonMenu from "../uiComponents/BottonMenu";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import "../Components/Component-css/Header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { LanguageContext } from "./LanguageContext";
import { useContext } from "react";
import { useTheme } from "../../ThemeContext";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const addsetting = localStorage.getItem("sitSetting");

const addCaseSetting = JSON.parse(addsetting);
const udata = localStorage.getItem("udata");
const profileData = JSON.parse(udata);

const Header = ({ user }) => {
  // console.log(user)

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++
  const [isdropdownVisible, setIsdropdownVisible] = useState(false);

  const toggleDiv = () => {
    setIsdropdownVisible(!isdropdownVisible);
  };

  const history = useHistory();

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  //  Download App
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  // const handleChange = (event) => {
  //   changeLanguage(event.target.value);
  // };

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  // language
  const { changeLanguage } = useContext(LanguageContext);
  const translations = {
    hi: {
      commission: "कमीशन: 5% : रेफरल: सभी खेलों के लिए 3%",
      profile: "मेरी प्रोफाइल",
      play: "खेले",
      wallet: "वॉलेट",
      refer: "रेफरल और कमाएँ",
      history: "इतिहास",
      support: "सहायता",
      app: "हमारा ऐप डाउनलोड करें",
      kyc : "केवाईसी"
    },
    en: {
      commission: "COMMISSION: 5% : REFERRAL: 3% FOR ALL GAMES",
      profile: "My Profile",
      play: "Play",
      wallet: "My Wallet",
      refer: "Refer and Earn",
      history: "History",
      support: "Support",
      app: "Download Our App ",
      kyc: "KYC",
    },
  };

  const { language } = useContext(LanguageContext);

  // change theme 
  const { theme, toggleTheme } = useTheme("light");
  const theme1  = localStorage.getItem('theme')

  const styles = {
    backgroundColor: theme1 === 'light' ? '#fff' : 'black',
    color: theme1 === 'light' ? 'black' : '#fff',
    // padding: '20px',
    // margin: '20px',
  };
  const styles1 = {
    backgroundColor: theme1 === 'light' ? '#fff' : '#6c757d',
    color: theme1 === 'light' ? 'black !important' : '#fff !important',
    // padding: '20px',
    // margin: '20px',
  };
  const logo = theme1 === 'light' ? '/nkblacklogo.png' : '/nkwhitelogo.png';

  const colorChange = () => {
    localStorage.setItem("theme", theme)
    toggleTheme('dark')
  }

 


  return (
    <>
      <div>
        <div className="header_top_message ">
          <span className="">{translations[language].commission}</span>
        </div>
        {access_token ? (
          <React.Fragment>
            <div id="sidebarOverlay" onClick={w3_close}></div>
            <div style={styles}>

            <div
              className="w3-sidebar w3-bar-block"
              id="mySidebar"
              style={{paddingBottom: "70px" }}
            >
              <div className="header_profile">
                <div>
                  <div>
                    {profileData && profileData?.avatar ? (
                      <img
                        src={baseUrl + `${profileData && profileData?.avatar}`}
                        alt=""
                        className="header_user"
                      />
                    ) : (
                      <img
                        src="/images/avatars/Avatar1.png"
                        alt=""
                        className="header_user"
                      />
                    )}
                  </div>
                  <div>
                    <h5 style={{ marginBottom: "0px" }}>{profileData?.Name}</h5>
                  </div>
                  <div>
                    {profileData && profileData?.Phone ? (
                      <h3 style={{ marginBottom: "0px" }}>
                        {profileData?.Phone}
                      </h3>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <Link
                to={"/Profile"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  {user && user?.avatar ? (
                    <img
                      width="30px"
                      height="30px"
                      src={baseUrl + `${user && user?.avatar}`}
                      alt="profile"
                      style={{ borderRadius: "50px" }}
                    />
                  ) : (
                    <img src="../images/icons/user.png" alt="profile" />
                  )}
                </picture>
                <h3>{translations[language].profile}</h3>
              </Link>
              <Link
                to={"/kyc2"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  {user && user?.avatar ? (
                    <img
                      width="30px"
                      height="30px"
                      src={baseUrl + `${user && user?.avatar}`}
                      alt="profile"
                      style={{ borderRadius: "50px" }}
                    />
                  ) : (
                    <img src="../images/icons/user.png" alt="profile" />
                  )}
                </picture>
                <h3>{translations[language].kyc}</h3>
              </Link>

              <Link
                to={"/Homepage/ludoClassicManual"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  <img
                    alt="img"
                    src="../images/icons/play.jpg"
                    style={{ height: "22px" }}
                  />
                </picture>
                <h3>{translations[language].play}</h3>
              </Link>
              <Link
                to={"/wallet"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  <img alt="img" src="../images/icons/wallet.png" />
                </picture>
                <h3>{translations[language].wallet}</h3>
              </Link>

              {addCaseSetting?.isReferral ? (
                <>
                  <Link
                    to={"/refer"}
                    className="w3-bar-item w3-button sidebar_link"
                    onClick={w3_close}
                  >
                    <picture className="icon">
                      <img alt="img" src="../images/icons/gift-card.png" />
                    </picture>
                    <h3>{translations[language].refer}</h3>
                  </Link>
                </>
              ) : (
                ""
              )}

              <Link
                to={"/history"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  <img alt="img" src="../images/icons/history.png" />
                </picture>
                <h3>{translations[language].history}</h3>
              </Link>

              {/* <Link
                to={"/Notification"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  <img alt="img" src="../images/icons/bells.png" />
                </picture>
                <h3>Notification</h3>
              </Link> */}

              <Link
                to={"/support"}
                className="w3-bar-item w3-button sidebar_link"
                onClick={w3_close}
              >
                <picture className="icon">
                  <img alt="img" src="../images/icons/customer-support.png" />
                </picture>
                <h3>{translations[language].support}</h3>
              </Link>

              <div
                className="w3-bar-item w3-button sidebar_link"
                onClick={onClick}
              >
                <picture className="icon">
                  <img alt="img" src="../images/icons/download.png" />
                </picture>
                <h3>{translations[language].app}</h3>
              </div>

              <div className="w3-bar-item w3-button sidebar_link ">
                <picture className="icon">
                  <img alt="img" src="../images/icons/translate.png" />
                </picture>

                {/* <button class="on"  onClick={() => changeLanguage('en')}>English</button>
	    <button class="off" onClick={() => changeLanguage('hi')}>Hindi</button> */}

                <div className="toggle-container">
                  <button
                    className={`toggle-button ${
                      language === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                  <button
                    className={`toggle-button ${
                      language === "hi" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("hi")}
                  >
                    Hindi
                  </button>
                </div>
              </div>
              <div
                className="w3-bar-item w3-button sidebar_link"
                onClick={toggleDiv}
                >
                <picture className="icon">
                  <img alt="img" src="../images/icons/policy.png" />
                </picture>
                <h3>{isdropdownVisible ? "All Policy" : "All Policy"}</h3>
              </div>
              {isdropdownVisible && (
                <ul className="dropdown_link">
                  <li>
                    <Link to="/privacy-policy" onClick={w3_close}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy" onClick={w3_close}>
                      Refund/Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/term-condition" onClick={w3_close}>
                      Terms &amp; Condition
                    </Link>
                  </li>
                  <li>
                    <Link to="/tds-policy" onClick={w3_close}>
                      TDS Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/responsible-gaming" onClick={w3_close}>
                      Responsible Gaming
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={w3_close}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" onClick={w3_close}>
                      Contact
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            </div>

            <div className="w3-teal" style={styles}>
              <div className="w3-container " style={styles}>
                <div className={`${css.headerContainer} `} style={styles}>
                  <button
                    className=" w3-xlarge float-left menu_button "
                    onClick={w3_open}
                    id="hambergar"
                  >
                    <picture className={`${css.sideNavIcon} mr-0`}>
                      <GiHamburgerMenu style={styles} />
                    </picture>
                  </button>
                  <Link to="/">
                    <img
                      src={logo}
                      className="snip-img ml-2"
                      alt="logos"
                      width="60px"
                      height="60px"
                    />
                  </Link>

                  <div className="top_menu_bar_main" style={styles}>
                    <nav className={`${css.menu_items}`}>
                      <div className="header_navlink_main">
                        <h5 style={styles}>Coins</h5>
                        <NavLink
                          exact
                          to="/Addcase"
                          className="top_menus_link wallet_balance_btn"
                          activeClassName=""
                        >
                          <FiPlus className="icons" />

                          <span> {user && user?.Wallet_balance}</span>
                          {/* {console.log(user?.Wallet_balance)} */}
                        </NavLink>
                      </div>

                      <div className="header_navlink_main">
                        <h5 style={styles}>Earning</h5>
                        <NavLink
                          exact
                          to="/redeem/refer"
                          className="top_menus_link wallet_balance_btn ml-2 wallet_btn"
                          activeClassName=""
                        >
                          <FiPlus className="icons" />
                          <span> {user && user.referral_wallet}</span>
                        </NavLink>
                      </div>

                      <div className="header_navlink_main">
                        {/* <input type="checkbox" class="checkbox" id="checkbox" />
                        <label for="checkbox" class="checkbox-label">
                          <i class="fas fa-moon" onClick={() => toggleTheme('dark')}></i>
                          <i class="fas fa-sun" onClick={() => toggleTheme('light')}></i>
                          <span class="ball"></span>
                        </label> */}
                        <button class="dlmbtn" onClick={colorChange}>
                         
                         {
                          theme1 === "light" ? <img src="/moon.png"  alt="" width="20px" /> :
                          <img src="/sun.png"  alt="" width="20px" />
                         } 
                          </button>
                      </div>

                      {/* <NavLink
                        exact
                        to="/Notification"
                        className="top_menus_link notification_btn"
                        activeClassName="active_link"
                      >
                        <IoMdNotificationsOutline className="icons" />
                      </NavLink> */}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="w3-teal " style={styles}>
            <div className="w3-container ">
              <div className={`${css.headerContainer} justify-content-between`}>
                <Link to="/">
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img
                      src="/nkblacklogo.png"
                      className="snip-img"
                      alt="NKADDA"
                      width="30px"
                    />
                  </picture>
                </Link>

                <div className={`ml-5`}>
                  <Link type="button" className="login-btn" to="/login">
                    LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bottom_menus_main">
        <BottonMenu />
      </div>
    </>
  );
};

export default Header;
