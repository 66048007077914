import React, { useContext } from "react";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";
import { colors } from "@mui/material";

export default function RunningCard({ runnig, winnAmount, game_type }) {
  const user = localStorage.getItem("userId");
  // console.log(user,"running")

  // language
  const translations = {
    hi: {
      view: "देखे",
      prize: "इनाम",
      entry: "प्रवेश शुल्क",
    },
    en: {
      view: "view",
      entry: "Entry Fee ",
      prize: "Prize",
    },
  };

  const { language } = useContext(LanguageContext);

  // change theme
  const theme  = localStorage.getItem('theme')

  const styles = {
    color: theme === "light" ? "black" : "black",
  };

  return (
    <>
      <div
        className={`${css.betCard} mt-1 battle_card_main card_bottom_border`}
      >
        <div className="d-flex">
          <span
            className={`${css.betCardTitle} pl-3 d-flex align-items-center headings`}
            style={{ color: "#0c5a0c" }}
          >
            Playing For
          </span>
          <span
            className={`${css.betCardTitle} pl-3 d-flex align-items-center headings`}
            style={{ marginLeft: "6px", color: "rgb(87 21 90)" }}
          >
            {runnig.Created_by.Name.length > 5 ? `${runnig.Created_by.Name.slice(0, 5)}...` : runnig.Created_by.Name}

          </span>
          <span
            className={`${css.betCardTitle} pl-3 d-flex align-items-center headings`}
            style={{ marginLeft: "-32px", color: "rgb(87 21 90)" }}
          >
            &
          </span>
          <span
            className={`${css.betCardTitle} pl-3 d-flex align-items-center headings `}
            style={{ marginLeft: "-70px" }}
          >


             {runnig.Accepetd_By.Name.length > 5 ? `${runnig.Accepetd_By.Name.slice(0, 5)}....` : runnig.Accepetd_By.Name}
          </span>

          <div
            className={`${css.betCardTitle} d-flex align-items-center headings`}
          >
            <span className="ml-auto mr-3"></span>
          </div>
        </div>
        <div className="py-1 row battle_card_inner_bottom">
          <div className="pr-3 text-center col-5" style={{marginLeft:"-37px"}}>
          
            <div className="">
              <span className={css.betCardSubTitle} style={{styles}}>
                {translations[language].entry}
              </span>
              <div>
                <img
                  className="mx-1"
                  src="/nkcoin.png"
                  alt=""
                  width="20x"
                  height="20x"
                />
                <span className={css.betCardAmount} style={{color:"black", marginLeft:"2px"}}>{runnig.Game_Ammount}</span>
              </div>
            </div>
          </div>
          <div className="pr-3 text-center col-2 cxy" style={{top:"10px"}}>
            <div>
              {user == runnig.Accepetd_By._id ||
              user == runnig.Created_by._id ? (
                <Link
                  className={`${css.bgSecondary} ${css.playButton} ${
                    css.cxy
                  } position-relative m-2 mx-1 ${
                    runnig.Status == "conflict" ? "bg-danger" : "bg-success"
                  }`}
                  style={{
                    right: "0px",
                    top: "-6px",
                    padding: "10px 17px",
                  }}
                  to={{
                    pathname: `/viewgame1/${runnig._id}`,
                    state: { prevPath: window.location.pathname },
                  }}
                >
                  {translations[language].view}
                </Link>
              ) : (
                <img
                  className="mx-1"
                  src={process.env.PUBLIC_URL + "/images/Homepage/versus.png"}
                  alt=""
                  width="45x"
                  height="45x"
                />
              )}
            </div>
          </div>
          <div className="text-center col-5" style={{marginRight:"-38px"}}>
            <div className="">
              <span className={css.betCardSubTitle} style={{color:"black"}}>
                {translations[language].prize}
              </span>
              <div>
                <img
                  className="mx-1"
                  src="/nkcoin.png"
                  alt=""
                  width="20x"
                  height="20x"
                />
                <span className={css.betCardAmount} style={{color:"black"}}>
                  {runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
