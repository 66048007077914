import React, { useContext } from "react";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import { Link } from "react-router-dom";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";

const Support = ({ handleShow }) => {
   const theme  = localStorage.getItem('theme')

  // language 
  const translations = {
    hi: {
      contact: 'नीचे दिए गए प्लेटफ़ॉर्म पर हमसे संपर्क करें।',
      money: 'राशि डालें  ',
      next: 'आगे बड़े ',
      addAmount: 'राशि जोड़ी जानी है ',
      referPerson: 'रेफ़रल | ',
    },
    en: {
      contact: 'Contact Us At Below Platforms.',
      money: 'Enter Amount',
      next: 'Next',
      addAmount: 'Amount to be added',
      referPerson: 'Referral',
    },
  };

  const { language } = useContext(LanguageContext);

  
  // change theme 
 const styles = {
  backgroundColor: theme === 'light' ? '#fff' : 'black',
  color: theme === 'light' ? 'black' : '#fff',
};

  return (
    <div>
      <div
        className="leftContainer mb_space"
        style={styles}
      >
        <div className="pt-5 mt-5  Orher_page_main_section">
          <div className=" ">
            <div className="profile_headings mt-4" style={styles}>
              {translations[language].contact}
            </div>
            <div className="row mt-3">
              {/* youtube */}
              {/* <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                <div className="support_samll_card_btn_type">
                  <a
                    className="cxy flex-column"
                    target="_blank"
                    href="https://youtu.be/0hNHiccgoMI?si=njTT0HryRxmQtWq7"
                  >
                    <img width="50px" src="/images/youtube.png" alt="" />
                    <span className="">Youtube</span>
                  </a>
                </div>
              </div> */}

              {/* mail */}
              <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                <div className="support_samll_card_btn_type">
                  <a className="cxy flex-column" href="mailto:info@nkadda.com">
                    <img
                      width="50px"
                      src={process.env.PUBLIC_URL + "/images/mail.png"}
                      alt=""
                    />
                    <span className="">
                      {JSON.parse(localStorage.getItem("sitSetting")) ==
                      "undefined"
                        ? ""
                        : JSON.parse(localStorage.getItem("sitSetting"))
                            ?.supportMail}
                    </span>
                  </a>
                </div>
              </div>

              {/* whatsapp */}
              <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                <div className="support_samll_card_btn_type">
                  {JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined" ? (
                    ""
                  ) : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.whatsappSupport ? (
                    <>
                      <a
                        href={
                          JSON.parse(localStorage.getItem("sitSetting")) ==
                          "undefined"
                            ? ""
                            : JSON.parse(localStorage.getItem("sitSetting"))
                                ?.whatsappSupport
                        }
                        target="_blank"
                      >
                        <img
                          width="50px"
                          src={process.env.PUBLIC_URL + "/images/whatsapp.png"}
                          alt=""
                        />
                        <span className="">WhatsApp</span>
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* call */}
              <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                <div className="support_samll_card_btn_type">
                  <img
                    width="50px"
                    src={process.env.PUBLIC_URL + "/images/phone.png"}
                    alt=""
                  />
                  <span className="">
                    <a
                      target="_blank"
                      href={
                        JSON.parse(localStorage.getItem("sitSetting")) ==
                        "undefined"
                          ? ""
                          : "tel:" +
                            JSON.parse(localStorage.getItem("sitSetting"))
                              ?.CompanyMobile
                      }
                    >
                      {JSON.parse(localStorage.getItem("sitSetting")) ==
                      "undefined"
                        ? ""
                        : JSON.parse(localStorage.getItem("sitSetting"))
                            ?.CompanyMobile}
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <p className="company_address" style={styles}>
                 <span style={styles}>Address:</span> 
                  <span style={styles}>
                    {JSON.parse(localStorage.getItem("sitSetting")) ==
                    "undefined"
                      ? ""
                      : JSON.parse(localStorage.getItem("sitSetting"))
                          ?.CompanyAddress}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;
